import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons'

import VueMobileDetection from "vue-mobile-detection";
Vue.use(VueMobileDetection);

Vue.config.productionTip = false
library.add(fas,far,fab)
Vue.component('font-awesome-icon', FontAwesomeIcon)

const EventBus = new Vue();
Object.defineProperties(Vue.prototype, {
  $eventBus: {
    get: function () {
      return EventBus;
    }
  }
});


const siteVue = new Vue({  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app')


window.addEventListener("message", receiveMessage, false);
function receiveMessage(event) {
  siteVue.$eventBus.$emit('snsLoginResult',event.data);

}

window.snsLoginResult = function(data) {
  siteVue.$eventBus.$emit('snsLoginResult',data);

}
