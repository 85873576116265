
export default ({
	namespaced: true,
	state: {
		pageMenuName:'',
		siteEnv:'',
	},
	getters: {
		getPageMenuName: state => {
			return state.pageMenuName;
		},
		getSiteEnv: state => {
			return state.siteEnv;
		},

	},
	mutations: {
		setPageMenuName(state,name) {
			state.pageMenuName = name;
		},
		setSiteEnv(state,env) {
			state.siteEnv = env;
		},

	},
	actions: {
		setPageMenuName({commit},name) {
			commit('setPageMenuName',name)
		},
		setSiteEnv({commit},env) {
			commit('setSiteEnv',env)
		},
    }
})
