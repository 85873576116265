<template>
    <v-app>
        <app-modal></app-modal>
        <app-overlay></app-overlay>
        <router-view/>
    </v-app>
</template>

<style>
@font-face {
    font-family: 'Nanum Gothic';
    src: url('./assets/fonts/NanumGothic-Regular.ttf') format('truetype');
}
@font-face {
    font-family: 'NanumGothicBold';
    src: url('./assets/fonts/NanumGothic-Bold.ttf') format('truetype');
    font-weight: 200;
}

@font-face {
    font-family: 'NanumGothicExtraBold';
    src: url('./assets/fonts/NanumGothic-ExtraBold.ttf') format('truetype');
    font-weight: 400;
}
body {
    font-family: 'Nanum Gothic', sans-serif !important;
    font-size: 13px;

}

td,th {
    font-family: 'Nanum Gothic', sans-serif !important;
    font-size: 14px;
}
div,p,span,input,select,li {
    font-family: 'Nanum Gothic', sans-serif !important;
    font-size: 12px;
}
.page-container {
    padding:0;
    margin:0;

}

.page-container div,p,span,input,select,li {
    font-family: 'Nanum Gothic', sans-serif !important;
    font-size: 13px;
}
.white-button {
    background: #fff !important;
    border:solid 1px #ccc;
}
ul {
    padding:0;
    margin:0;
    list-style-type: none;
    li {
        padding:0;
        margin:0;
    }
}

</style>
<script>
import Modal from './common/Modal'
import Overlay from './common/Overlay'

export default {
    name: 'App',
    components: {
        'app-modal': Modal,
        'app-overlay': Overlay
    },
    created() {


    }
};
</script>