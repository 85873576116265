import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    /**
     * @ 사용자
     */
    {
        path: "/",
        component: () => import("../views/WEB/index"),
        redirect: `/main`,
        children: [
            {
                path: "main",
                name: "siteMain",
                component: () => import("../views/WEB/main/main"),
            },
            {
                path: "memberLogin",
                name: "memberLogin",
                component: () => import("../views/WEB/member/login"),
            },
            {
                path: "memberFindPass",
                name: "memberFindPass",
                component: () => import("../views/WEB/member/findPass"),
            },
            {
                path: "memberJoin",
                name: "memberJoin",
                component: () => import("../views/WEB/member/join"),
            },
            {
                path: "member/agree/:code",
                name: "memberAgree",
                component: () => import("../views/WEB/member/agree"),
            },

            {
                path: "joinAgree",
                name: "joinAgree",
                component: () => import("../views/WEB/member/joinAgree"),
            },
            {
                path: "order/:cartIds/:type",
                name: "order",
                component: () => import("../views/WEB/order/order"),
            },
            {
                path: "orderComplete/:oid",
                name: "orderComplete",
                component: () => import("../views/WEB/order/orderComplete"),
            },
            {
                path: "mypage",
                component: () => import("../views/WEB/mypage/index"),
                redirect: `mypage/main`,
                children: [
                    {
                        path: "main",
                        name: "mypageMain",
                        component: () => import("../views/WEB/mypage/main"),
                    },
                    {
                        path: "orderDetail/:id",
                        name: "mypageOrderDetail",
                        component: () => import("../views/WEB/mypage/orderDetail"),
                    },
                    {
                        path: "orderList",
                        name: "mypageOrderList",
                        component: () => import("../views/WEB/mypage/orderList"),
                        children: [
                            {
                                path: "reviewRegist/:oid/:pid",
                                name: "mypageOrderListWithRegist",
                                component: () => import("../views/WEB/mypage/MOBILE/components/reviewRegist"),
                            },
                        ]
                    },
                    {
                        path: "claim/:claimType/:oid",
                        name: "mypageClaim",
                        component: () => import("../views/WEB/mypage/claim"),
                    },
                    {
                        path: "reviewList",
                        name: "mypageReviewList",
                        component: () => import("../views/WEB/mypage/reviewList"),
                        children: [
                            {
                                path: "reviewRegist/:oid/:pid",
                                name: "mypageReviewListWithRegist",
                                component: () => import("../views/WEB/mypage/MOBILE/components/reviewRegist"),
                            },
                        ]

                    },
                    {
                        path: "pointList",
                        name: "mypagePointList",
                        component: () => import("../views/WEB/mypage/pointList"),
                    },
                    {
                        path: "memberModify",
                        name: "mypageMemberModify",
                        component: () => import("../views/WEB/mypage/memberModify"),
                    },
                    {
                        path: "shippingList",
                        name: "mypageShippingList",
                        component: () => import("../views/WEB/mypage/shippingList"),
                        children: [
                            {
                                path: "regist/:id",
                                name: "mypageShippingListWithRegist",
                                component: () => import("../views/WEB/mypage/MOBILE/components/shippingRegist"),
                            },
                        ]
                    },
                    {
                        path: "couponList",
                        name: "mypageCouponList",
                        component: () => import("../views/WEB/mypage/couponList"),
                    },
                ]
            },
            {
                path: "product/productList/:category",
                name: "productList",
                component: () => import("../views/WEB/product/productList"),
            },
            {
                path: "product/productView/:id/:categoryId",
                name: "productView",
                component: () => import("../views/WEB/product/productView"),
            },
            {
                path: "cart",
                name: "cart",
                component: () => import("../views/WEB/cart/cart"),
            },
            {
                path: "wish",
                name: "wish",
                component: () => import("../views/WEB/cart/wish"),
            },
            {
                path: "search",
                name: "search",
                component: () => import("../views/WEB/main/search"),
            },
            {
                path: "board/articleList/:bid",
                name: "boardArticleList",
                component: () => import("../views/WEB/board/articleList"),
            },
            {
                path: "board/articleRegist/:bid/:id",
                name: "boardArticleRegist",
                component: () => import("../views/WEB/board/articleRegist"),
            },
            {
                path: "board/articleView/:bid/:id",
                name: "boardArticleView",
                component: () => import("../views/WEB/board/articleView"),
            },
            {
                path: "customer",
                component: () => import("../views/WEB/board/customer"),
                redirect: `customer/faq`,
                children: [
                    {
                        path: "faq",
                        name: "customerFaqList",
                        component: () => import("../views/WEB/board/faqList"),
                    },
                    {
                        path: "notice",
                        name: "customerNoticeList",
                        component: () => import("../views/WEB/board/noticeList"),
                    },

                ]
            },


        ]
    },
    /**
     * @ 관리자
     */
    {
        path: "/admin",
        component: () => import("../views/ADMIN/index"),
        name: "adminIndex",
        redirect: `/admin/main`,
        children: [
            {
                path: "main",
                name: "adminMain",
                component: () => import("../views/ADMIN/main/main"),
            },
            {
                path: "adminUpdate",
                name: "adminUpdate",
                component: () => import("../views/ADMIN/main/adminUpdate"),
            },
            {
                path: "member",
                component: () => import("../views/ADMIN/member/index"),
                redirect: `/admin/member/memberList`,
                children: [
                    {
                        path: "memberList",
                        name: "adminMemberMemberList",
                        component: () => import("../views/ADMIN/member/memberList"),
                    },
                    {
                        path: "levelList",
                        name: "adminMemberLevelList",
                        component: () => import("../views/ADMIN/member/levelList"),
                    },
                    {
                        path: "outMemberList",
                        name: "adminMemberOutMemberList",
                        component: () => import("../views/ADMIN/member/outMemberList"),
                    },
                ]
            },
            {
                path: "setting",
                component: () => import("../views/ADMIN/setting/index"),
                redirect: `/admin/setting/categoryList`,
                children: [
                    {
                        path: "companyInfo",
                        name: "adminSettingCompanyInfo",
                        component: () => import("../views/ADMIN/setting/companyInfo"),
                    },
                    {
                        path: "categoryList",
                        name: "adminSettingCategoryList",
                        component: () => import("../views/ADMIN/setting/categoryList"),
                    },
                    {
                        path: "brandList",
                        name: "adminSettingBrandList",
                        component: () => import("../views/ADMIN/setting/brandList"),
                    },
                    {
                        path: "productAddInfo",
                        name: "adminSettingProductAddInfo",
                        component: () => import("../views/ADMIN/setting/productAddInfo"),
                    },
                    {
                        path: "deliveryList",
                        name: "adminSettingDeliveryList",
                        component: () => import("../views/ADMIN/setting/deliveryList"),
                    },
                    {
                        path: "deliveryCompany",
                        name: "adminSettingDeliveryCompany",
                        component: () => import("../views/ADMIN/setting/deliveryCompany"),
                    },
                    {
                        path: "payment",
                        name: "adminSettingPayment",
                        component: () => import("../views/ADMIN/setting/payment"),
                    },
                    {
                        path: "memberset",
                        name: "adminSettingMemberset",
                        component: () => import("../views/ADMIN/setting/memberset"),
                    },
                    {
                        path: "memberAgree",
                        name: "adminSettingMemberAgree",
                        component: () => import("../views/ADMIN/setting/memberAgree"),
                    },
                    {
                        path: "imageset",
                        name: "adminSettingImageset",
                        component: () => import("../views/ADMIN/setting/imageset"),
                    }
                ]
            },
            {
                path: "siteSetting",
                component: () => import("../views/ADMIN/siteSetting/index"),
                redirect: `/admin/siteSetting/logoSetting`,
                children: [
                    {
                        path: "logoSetting",
                        name: "adminSiteSettingLogoSetting",
                        component: () => import("../views/ADMIN/siteSetting/logoSetting"),
                    },
                    {
                        path: "menuSetting",
                        name: "adminSiteSettingMenuSetting",
                        component: () => import("../views/ADMIN/siteSetting/menuSetting"),
                    },
                    {
                        path: "mainSetting",
                        name: "adminSiteSettingMainSetting",
                        component: () => import("../views/ADMIN/siteSetting/mainSetting"),
                    },


                ]
            },
            {
                path: "smsEmailSetting",
                component: () => import("../views/ADMIN/smsEmail/index"),
                redirect: `/admin/smsEmail/smsSetting`,
                children: [
                    {
                        path: "smsSetting",
                        name: "adminSmsEmailSettingSmsSetting",
                        component: () => import("../views/ADMIN/smsEmail/smsSetting"),
                    },
                    {
                        path: "emailSetting",
                        name: "adminSmsEmailSettingEmailSetting",
                        component: () => import("../views/ADMIN/smsEmail/emailSetting"),
                    },
                ]
            },
            {
                path: "partner",
                component: () => import("../views/ADMIN/partner/index"),
                redirect: `/admin/partner/partnerList`,
                children: [
                    {
                        path: "partnerList",
                        name: "adminPartnerPartnerList",
                        component: () => import("../views/ADMIN/partner/partnerList"),
                    },
                    {
                        path: "partnerRegist",
                        name: "adminPartnerPartnerRegist",
                        component: () => import("../views/ADMIN/partner/partnerRegist"),
                    },
                ]
            },
            {
                path: "product",
                component: () => import("../views/ADMIN/product/index"),
                redirect: `/admin/product/productList`,
                children: [
                    {
                        path: "productList",
                        name: "adminProductProductList",
                        component: () => import("../views/ADMIN/product/productList"),
                    },
                    {
                        path: "productRegist",
                        name: "adminProductProductRegist",
                        component: () => import("../views/ADMIN/product/productRegist"),
                    },
                    {
                        path: "productModify/:id",
                        name: "adminProductProductModify",
                        component: () => import("../views/ADMIN/product/productRegist"),
                    },
                    {
                        path: "productReview",
                        name: "adminProductReviewList",
                        component: () => import("../views/ADMIN/product/reviewList"),
                    },
                    {
                        path: "productInquire",
                        name: "adminProductInquireList",
                        component: () => import("../views/ADMIN/product/inquireList"),
                    },

                ]
            },
            {
                path: "inventory",
                component: () => import("../views/ADMIN/inventory/index"),
                redirect: `/admin/inventory/productList`,
                children: [
                    {
                        path: "productList",
                        name: "adminInventoryProductList",
                        component: () => import("../views/ADMIN/inventory/productList"),
                    },
                    {
                        path: "optionList",
                        name: "adminInventoryOptionList",
                        component: () => import("../views/ADMIN/inventory/optionList"),
                    },
                ]
            },
            {
                path: "order",
                component: () => import("../views/ADMIN/order/index"),
                redirect: `/admin/order/orderListALL`,
                children: [
                    {
                        path: "orderListALL",
                        name: "adminOrderOrderListALL",
                        component: () => import("../views/ADMIN/order/orderListALL"),
                    },
                    {
                        path: "orderListNP",
                        name: "adminOrderOrderListNP",
                        component: () => import("../views/ADMIN/order/orderListNP"),
                    },
                    {
                        path: "orderListIC",
                        name: "adminOrderOrderListIC",
                        component: () => import("../views/ADMIN/order/orderListIC"),
                    },
                    {
                        path: "orderListDR",
                        name: "adminOrderOrderListDR",
                        component: () => import("../views/ADMIN/order/orderListDR"),
                    },
                    {
                        path: "orderListDI",
                        name: "adminOrderOrderListDI",
                        component: () => import("../views/ADMIN/order/orderListDI"),
                    },
                    {
                        path: "orderListDC",
                        name: "adminOrderOrderListDC",
                        component: () => import("../views/ADMIN/order/orderListDC"),
                    },
                    {
                        path: "orderListOC",
                        name: "adminOrderOrderListOC",
                        component: () => import("../views/ADMIN/order/orderListOC"),
                    },
                    {
                        path: "orderView/:id",
                        name: "adminOrderOrderView",
                        component: () => import("../views/ADMIN/order/orderView"),
                    },
                    {
                        path: "cancleList",
                        name: "adminOrderCancleList",
                        component: () => import("../views/ADMIN/order/cancleList"),
                    },
                    {
                        path: "returnList",
                        name: "adminOrderReturnList",
                        component: () => import("../views/ADMIN/order/returnList"),
                    },
                    {
                        path: "exchangeList",
                        name: "adminOrderExchangeList",
                        component: () => import("../views/ADMIN/order/exchangeList"),
                    },
                    {
                        path: "refundList",
                        name: "adminOrderRefundList",
                        component: () => import("../views/ADMIN/order/refundList"),
                    },
                ]
            },
            {
                path: "run",
                component: () => import("../views/ADMIN/run/index"),
                redirect: `/admin/run/boardList`,
                children: [
                    {
                        path: "boardList",
                        name: "adminRunBoardList",
                        component: () => import("../views/ADMIN/run/boardList"),
                    },
                    {
                        path: "pointList",
                        name: "adminRunPointList",
                        component: () => import("../views/ADMIN/run/pointList"),
                    },
                    {
                        path: "couponList",
                        name: "adminRunCouponList",
                        component: () => import("../views/ADMIN/run/couponList"),
                    },
                    {
                        path: "faqList",
                        name: "adminRunFaqList",
                        component: () => import("../views/ADMIN/run/faqList"),
                    },

                ]
            },
            {
                path: "statistics",
                component: () => import("../views/ADMIN/statistics/index"),
                redirect: `/admin/statistics/joinMemberStatistics`,
                children: [
                    {
                        path: "joinMemberStatistics",
                        name: "adminStatisticsJoinMember",
                        component: () => import("../views/ADMIN/statistics/joinMemberStatistics"),
                    },
                    {
                        path: "orderMemberStatistics",
                        name: "adminStatisticsOrderMemberStatistics",
                        component: () => import("../views/ADMIN/statistics/orderMemberStatistics"),
                    },
                    {
                        path: "orderProductStatistics",
                        name: "adminStatisticsOrderProductStatistics",
                        component: () => import("../views/ADMIN/statistics/orderProductStatistics"),
                    },
                    {
                        path: "orderDateStatistics",
                        name: "adminStatisticsOrderDateStatistics",
                        component: () => import("../views/ADMIN/statistics/orderDateStatistics"),
                    },

                ]
            },
            {
                path: "developer",
                component: () => import("../views/ADMIN/developer/index"),
                redirect: `/admin/developer/setting`,
                children: [
                    {
                        path: "setting",
                        name: "adminDeveloperSetting",
                        component: () => import("../views/ADMIN/developer/setting"),
                    },
                    {
                        path: "batch",
                        name: "adminDeveloperBatch",
                        component: () => import("../views/ADMIN/developer/batch"),
                    },

                ]
            },

        ]
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior(to, from, savedPosition) {

        return {x: 0, y: 0}
    },
    routes
})

export default router
